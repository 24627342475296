import * as React from 'react'
import Layout from '../../../layout'

import openGraphShareCard from '../../../images/opengraph-sharecard.png'

import Helmet from '../../../components/Helmet'
import GroupPage from '../../../pagesComponents/groups/group'

const Group = ({ id }) => {
	return (
		<Layout>
			<Helmet
				title="Join my Fairgame Group"
				ogTitle="I’m inviting you to join my Fairgame group"
				description="The funfair. exactly like you don't remember. Brand spankin' new venue in Canary Wharf! Welcome."
				image={openGraphShareCard}
			/>

			<GroupPage id={id} />
		</Layout>
	)
}

export default Group
