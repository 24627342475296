import React, { useEffect, useRef, useState } from 'react'
import BasePage from '../../components/basePage'
import PlayerItem from '../../components/playerItem'
import Modal from '../../components/modal'
import { Flex, Button, useToast, Text } from '@chakra-ui/react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useAuth } from '../../hooks/useAuth'
import { navigate } from 'gatsby'
import {
	getGroup,
	getGroupMembers,
	joinGroup,
	leaveGroup,
	removeUserFromGroup,
	updateGroup
} from '../../services/group'
import Spinner from '../../components/spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { RWebShare } from 'react-web-share'
import { errorToastOptions } from '../../helpers'
import Input from '../../components/input'
import eventTracking from '../../services/eventTracking'
import ShareBlack from '../../icons/share-black.svg'
import RFIDLinkButton from '../../components/RFIDLinkButton'

import LocalTextFit from '../../components/textFit'

const GroupPage = ({ id }) => {
	const toast = useToast()
	const queryClient = useQueryClient()
	const { user } = useAuth()
	const [editing, setEditing] = useState(false)
	// const [deleteModal, toggleDeleteModal] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [leaveModal, toggleLeaveModal] = useState(false)
	const [memberModal, toggleMemberModal] = useState(false)
	const [maxReached, setMaxReached] = useState(false)

	const resolver = yupResolver(
		object()
			.shape({
				name: string().notBadWords().trim().required().min(2)
			})
			.required()
	)

	const isMounted = useRef(false)

	const groupQuery = useQuery(['group', id, { userId: user?.uid }], getGroup)
	const groupMembersQuery = useQuery(['groupMembers', id], getGroupMembers)

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		// defaultValues: async () => fetch('/api-endpoint'),
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	// const name = watch(({ name }) => {
	// 	if (name.length === 18) {
	// 		return setMaxReached(true)
	// 	} else if (maxReached) {
	// 		return setMaxReached(false)
	// 	}
	// })

	const rfid =
		groupMembersQuery?.data?.find((member) => member.id === user.uid)
			?.rfid || false

	const userIsMemberOfGroup = groupQuery?.data?.currentUserIsMemberOfGroup

	const shareUrl =
		typeof window !== 'undefined'
			? `${window.location.origin}/groups/${id}`
			: ''
	const shareData = {
		title: `Fairgame Group: ${groupQuery.data?.name || ''}`,
		text: `I’m inviting you to join the group “${
			groupQuery.data?.name || ''
		}” so we can play together at Fairgame`,
		url: shareUrl
	}

	const nameMutation = useMutation(updateGroup, {
		onSuccess: async (data) => {
			await groupQuery.refetch()

			await queryClient.invalidateQueries(['group', id], {
				exact: true
			})
			setEditing(false)
		},
		onError: (error) => {
			if (isMounted.current) {
				toast({
					description: error.message || error,
					...errorToastOptions
				})
			}
		}
	})

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const joinGroupMutation = useMutation(joinGroup, {
		onSuccess: async (data) => {
			setOpenModal(true)
			await Promise.all([
				queryClient.invalidateQueries(['group', id]),
				queryClient.invalidateQueries(['groupMembers', id], {
					exact: true
				})
			])
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const leaveGroupMutation = useMutation(leaveGroup, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['group'], { exact: true })
			await queryClient.invalidateQueries(['groupMembers', id], {
				exact: true
			})
			toggleLeaveModal(null)
			await navigate('/groups/')
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const removeUserMutation = useMutation(removeUserFromGroup, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['group'], {
				exact: true
			})
			await queryClient.invalidateQueries(['groupMembers', id], {
				exact: true
			})
			toggleMemberModal(null)
			await groupQuery.refetch()
			await groupMembersQuery.refetch()
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onRemoveUser = (item) => {
		toggleMemberModal(item)
	}

	const onLeaveGroup = () => {
		toggleLeaveModal(groupQuery.data)
	}

	const handleShare = async () => {
		if (typeof navigator !== 'undefined') {
			await navigator.share({
				text: `I’m inviting you to join the group “${
					groupQuery.data?.name || ''
				}” so we can play together at Fairgame: ${shareUrl}`
			})
		}

		eventTracking('share_group', {
			group_id: id,
			user_id: user?.uid
		})
	}

	const handleEditName = () => {
		const data = getValues()
		if (!data?.name) return
		nameMutation.mutate({ id: id, name: data.name })
	}

	const handleJoinGroup = () => {
		joinGroupMutation.mutate({ id })
	}

	const handleLeaveGroup = () => {
		leaveGroupMutation.mutate({ id })
	}

	const handleRemoveUser = () => {
		removeUserMutation.mutate({
			id,
			memberId: memberModal.id
		})
	}

	useEffect(() => {
		const joinGroupAutoJoin =
			id && user?.uid && window.location.search.indexOf('autojoin') > -1

		const joinGroupJoined =
			groupQuery.status === 'success' &&
			(!userIsMemberOfGroup ||
				window.location.search.indexOf('joined') > -1)

		if (joinGroupAutoJoin || joinGroupJoined) {
			handleJoinGroup()

			if (joinGroupAutoJoin) {
				navigate(`/rfid/?group=${id}`) // Redirect without param after joining
			}
		}
	}, [id, user, groupQuery.status, userIsMemberOfGroup])

	useEffect(() => {
		if (
			groupQuery.status === 'error' ||
			(groupQuery.status === 'success' && !groupQuery.data?.name)
		) {
			navigate('/groups')
		}

		const data = getValues()
		if (
			groupQuery.status === 'success' &&
			!!groupQuery.data?.name &&
			!data?.name
		) {
			setValue('name', groupQuery.data.name)
		}
	}, [groupQuery])

	useEffect(() => {
		if (editing) {
			const data = getValues()
			if (data?.name?.toLowerCase().includes("'s group")) {
				// If group name is default then clear the input on edit
				setValue('name', '')
			}
		}
	}, [editing])

	if (
		groupQuery.status === 'error' ||
		groupQuery.status === 'loading' ||
		groupMembersQuery.status === 'loading'
	) {
		return (
			<BasePage
				theme="dark"
				backBtn={`/groups/${id}/`}
				showMenuBtn={true}
			>
				{groupQuery.status === 'loading' && <Spinner />}
			</BasePage>
		)
	}

	return (
		<BasePage
			backBtn={`/groups`}
			theme="dark"
			showMenuBtn={true}
			rightBtn={
				userIsMemberOfGroup ? (
					<RFIDLinkButton groupId={id} rfid={rfid} />
				) : null
			}
		>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				mb="70px"
				w="100%"
			>
				{/* <Flex h="120px">
					<Image
						width="120px"
						height="120px"
						src={
							!!team?.avatar ? getImageById(team.avatar) : Avatar
						}
					/>
				</Flex> */}
				{editing ? (
					<Flex direction="column">
						<form onSubmit={handleSubmit(handleEditName)}>
							<Input
								type="text"
								name="name"
								mt="6px"
								px="20px"
								w="320px"
								minW="320px"
								maxW="320px"
								variant="filled"
								fontSize="36px"
								fontWeight="900"
								textAlign="center"
								register={register}
								errors={errors}
								onChange={(ev) => {
									console.log(
										'the value is',
										ev.currentTarget.value
									)
									if (ev.currentTarget.value.length >= 18) {
										ev.currentTarget.value =
											ev.currentTarget.value.substring(
												0,
												18
											)
										setValue(
											'name',
											ev.currentTarget.value.substring(
												0,
												18
											)
										)
									}
								}}
								autoFocus={true}
								// maxLength={18}
							/>
						</form>
						{maxReached && (
							<Text
								w="320px"
								minW="320px"
								maxW="320px"
								textAlign={'center'}
								color="pink"
								marginTop="10px"
								fontWeight="500"
							>
								You have reached the max number of characters
							</Text>
						)}
					</Flex>
				) : (
					<Text
						mt="20px"
						variant="xl"
						mb={'0'}
						color="white"
						w="100%"
						textAlign={'center'}
					>
						<LocalTextFit>
							{groupQuery.data?.name || ''}
						</LocalTextFit>
					</Text>
				)}
				{editing && (
					<Button
						type="submit"
						mt="10px"
						variant="text"
						fontWeight="500"
						fontSize="18px"
						lineHeight="20px"
						onClick={handleSubmit(handleEditName)}
						disabled={nameMutation.isLoading}
						isLoading={nameMutation.isLoading}
					>
						Save
					</Button>
				)}
				{!editing && (
					<Button
						mt="10px"
						variant="text"
						fontWeight="500"
						fontSize="18px"
						lineHeight="20px"
						onClick={() => setEditing(true)}
					>
						Edit name
					</Button>
				)}
				{!userIsMemberOfGroup && (
					<Button
						w="100%"
						my="30px"
						variant="primary"
						onClick={handleJoinGroup}
						isLoading={joinGroupMutation.isLoading}
						disabled={joinGroupMutation.isLoading}
					>
						Join Group
					</Button>
				)}
				{/* {!!userIsMemberOfGroup && (
					<Button
						w="100%"
						variant="secondaryBorder"
						mt="30px"
						mb="-20px"
						onClick={() => {
							navigate(`/rfid/?group=${id}`)
						}}
					>
						<WifiWhite style={{ marginRight: '8px' }} />
						Link RFID
					</Button>
				)} */}
				{!!userIsMemberOfGroup && (
					<>
						{typeof navigator !== 'undefined' && navigator.share ? (
							<Button
								mt="30px"
								mb="40px"
								w="100%"
								variant="primary"
								onClick={handleShare}
							>
								<ShareBlack
									style={{
										marginRight: 8
									}}
								/>
								Share Group
							</Button>
						) : (
							<RWebShare data={shareData}>
								<Button
									mt="30px"
									mb="40px"
									w="100%"
									variant="primary"
								>
									<ShareBlack
										style={{
											marginRight: 8
										}}
									/>
									Share Group
								</Button>
							</RWebShare>
						)}
					</>
				)}
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="center"
					w="100%"
					mt={'0'}
				>
					{(groupMembersQuery.data || []).map((item) => {
						return (
							<PlayerItem
								key={item.id}
								player={item}
								isMyself={item.id === user?.uid}
								isMember={!!userIsMemberOfGroup}
								isLastMember={
									groupQuery?.data?.numberOfMembers === 1 ||
									!!item?.rfid
								}
								onLeaveGroup={onLeaveGroup}
								onRemoveUser={onRemoveUser}
							/>
						)
					})}
				</Flex>
			</Flex>
			<Modal
				opened={openModal}
				onSubmit={handleJoinGroup}
				// onClose={() => setOpenModal(null)}
				title="Congrats!"
				description="You’ve joined"
				group={groupQuery.data}
				isPink={true}
				primaryBtn={
					<Button
						variant="primary"
						onClick={() => setOpenModal(false)}
					>
						Got it
					</Button>
				}
				secondaryBtn={
					<Button
						onClick={onLeaveGroup}
						variant="text"
						fontWeight="500"
						fontSize="18px"
						lineHeight="20px"
						color={'black'}
						mt="5"
					>
						Not your group?
					</Button>
				}
			/>
			<Modal
				opened={!!leaveModal}
				// onClose={() => toggleLeaveModal(null)}
				title={
					<>
						Leave <br />
						group?
					</>
				}
				isPink
				description="Are you sure you want to permanently leave this group?"
				member={leaveModal}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={leaveGroupMutation.isLoading}
						disabled={leaveGroupMutation.isLoading}
						onClick={handleLeaveGroup}
					>
						Leave
					</Button>
				}
				secondaryBtn={
					<Button
						variant="cancel"
						onClick={() => toggleLeaveModal(null)}
					>
						Cancel
					</Button>
				}
			/>
			<Modal
				opened={!!memberModal}
				// onClose={() => toggleMemberModal(null)}
				title={
					<>
						Are you <br />
						sure?
					</>
				}
				description="Are you sure you want to delete this player from the group? They will no longer be able to play."
				member={memberModal}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={removeUserMutation.isLoading}
						disabled={removeUserMutation.isLoading}
						onClick={handleRemoveUser}
					>
						Delete
					</Button>
				}
				secondaryBtn={
					<Button
						variant="cancel"
						onClick={() => toggleMemberModal(null)}
					>
						Cancel
					</Button>
				}
			/>
		</BasePage>
	)
}
export default GroupPage
